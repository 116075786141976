.form-container[data-v-19309e79] {
  border: 1px solid #eee;
}
.form-container[data-v-19309e79] .form-section .form-column:first-child {
  padding-left: 15px;
}
.form-container[data-v-19309e79] .section-head {
  padding: var(--padding-sm) var(--padding-md);
}
.form-container[data-v-19309e79] .form-section.card-section {
  padding: var(--padding-xs) !important;
}
.form-container[data-v-19309e79] .layout-main-section-wrapper {
  margin-bottom: 8px;
}
.form-container[data-v-19309e79] .form-section .section-body:first-child {
  margin-top: var(--margin-sm) !important;
}
.form-container .placeholder[data-v-19309e79] {
  height: 500px;
  line-height: 500px;
  text-align: center;
}
